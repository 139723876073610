@charset "UTF-8";
/* **************************************


     追加


***************************************/
/* =============================================================================


     index


============================================================================= */
/* =============================================================================


     mv


============================================================================= */
.mv-headbox {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
}

@media (max-width: 767px) {
  .mv-headbox {
    writing-mode: vertical-rl;
    height: 100%;
    width: auto;
    margin: auto 20px;
    transform: none;
    top: 80px;
  }
}

.mv-img {
  position: relative;
  right: -360px;
  top: -60px;
  margin-left: auto;
  width: 1098px;
  display: block;
}

@media (max-width: 1228px) {
  .mv-img {
    right: -40px;
  }
}

@media (max-width: 767px) {
  .mv-img {
    width: 100vw;
    right: 0;
    top: 30px;
    left: 0;
    right: auto;
  }
}

.mv-head {
  display: inline;
  background: #fff;
  font-size: 3.6rem;
  font-weight: bold;
  line-height: 2;
}

.mv-head span {
  color: #f7b525;
}

@media (max-width: 767px) {
  .mv-head {
    writing-mode: vertical-rl;
    font-size: clamp(2rem, 5vw, 3.4rem);
    display: inline;
  }
}

.mv-deco01 {
  width: 186px;
  top: 60px;
  position: absolute;
  left: 28%;
}

@media (max-width: 767px) {
  .mv-deco01 {
    width: 18vw;
    right: 30px;
    left: auto;
    bottom: -72px;
    top: auto;
  }
}

.mv-deco02 {
  width: 384px;
  bottom: 40px;
  left: 24%;
  position: absolute;
}

@media (max-width: 767px) {
  .mv-deco02 {
    width: 36.267vw;
    max-width: 300px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -60px;
  }
}

.mv-deco03 {
  width: 172px;
  position: absolute;
  left: -100px;
  bottom: 40px;
  position: absolute;
}

@media (max-width: 767px) {
  .mv-deco03 {
    width: 17.6vw;
    max-width: 170px;
    left: 12px;
    bottom: -60px;
  }
}

.mv-txt {
  margin-top: 40px;
  font-size: 2rem;
}

.mv-txt span {
  font-weight: bold;
  background: linear-gradient(transparent 60%, #f7b525 40%);
}

@media (max-width: 767px) {
  .mv-txt {
    padding: 0 16px;
    position: relative;
    z-index: 1;
    font-size: 1.4rem;
    margin-top: clamp(60px, 18.667vw, 100px);
  }
  .mv-txt span {
    font-size: 2rem;
  }
}

/* =============================================================================


     about


============================================================================= */
.about {
  padding: 40px 0 100px;
}

.about-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  gap: 72px 44px;
  margin-top: 40px;
}

@media (max-width: 1228px) {
  .about-list {
    gap: 72px 44px;
  }
}

@media (max-width: 767px) {
  .about-list {
    gap: 72px 20px;
  }
}

.about-item {
  width: calc(33.3333% - ((44px * 2) / 3));
  position: relative;
}

@media (max-width: 1228px) {
  .about-item {
    width: calc(50% - ((22px) / 1));
  }
}

@media (max-width: 767px) {
  .about-item {
    width: 100%;
    max-width: 320px;
    margin: auto;
  }
}

.about-item-numbox {
  width: 74px;
  height: 74px;
  background: #704328;
  border-radius: 50%;
  color: #fff000;
  font-size: 1.4rem;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin: auto;
  z-index: 2;
  /*   position: absolute;
  transform: translateX(-50%); */
}

.about-itembox {
  background: #fff;
  box-shadow: 3px 3px 2px #2319161a;
  padding: 40px 30px 30px;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-top: -30px;
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
}

@media (max-width: 767px) {
  .about-itembox {
    padding: 30px 16px 20px;
  }
}

.about-itembox:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #704328;
  width: 100%;
  height: 20px;
}

.about-item-numtxt {
  border-bottom: 2px solid #fff;
  padding-bottom: 0.25em;
  line-height: 1;
}

@media (max-width: 767px) {
  .about-item-numtxt {
    font-size: 1.6rem;
  }
}

.about-item-num {
  font-size: 2.6rem;
  line-height: 1;
}

@media (max-width: 767px) {
  .about-item-num {
    font-size: 2.2rem;
  }
}

.about-item-head {
  font-size: 2rem;
  text-align: center;
  border-bottom: 2px dotted #5b3616;
  padding-bottom: 0.75em;
  margin-bottom: auto;
}

@media (max-width: 767px) {
  .about-item-head {
    font-size: 1.6rem;
  }
}

.about-item-img {
  margin-top: 20px;
  padding-top: 20px;
}

.about-btnbox {
  margin: 60px auto 0;
  text-align: center;
}

.about-btn {
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 400px;
  height: 120px;
  transition: 0.2s;
  background: #f7b525;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  border-radius: 30px;
  align-items: center;
}

.about-btn:hover {
  opacity: 0.7;
}

.about-btn span {
  width: 34px;
  height: 34px;
  background: #fff;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  border-radius: 50%;
}

.about-btn span:before {
  width: 25px;
  height: 6px;
  background: transparent url(../asset/images/common/arrow.svg) no-repeat top center/contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
}

.about-other {
  margin-top: 100px;
  position: relative;
}

.man-img {
  width: 240px;
  bottom: -350px;
  left: -100px;
  position: absolute;
}

@media (max-width: 767px) {
  .man-img {
    width: 120px;
    bottom: -190px;
    left: -32px;
  }
}

.woman-img {
  width: 240px;
  position: absolute;
  bottom: -350px;
  right: -100px;
}

@media (max-width: 767px) {
  .woman-img {
    width: 120px;
    bottom: -190px;
    right: -32px;
  }
}

/* =============================================================================


     merit


============================================================================= */
.merit {
  padding: 100px 0 40px;
  position: relative;
}

@media (max-width: 1228px) {
  .merit {
    padding-top: 60px;
  }
}

@media (max-width: 767px) {
  .merit {
    padding: 40px 0 40px;
  }
}

.merit-ball {
  width: 900px;
  height: 900px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  background: #fad07d;
}

@media (max-width: 1228px) {
  .merit-ball {
    width: 600px;
    height: 600px;
  }
}

@media (max-width: 767px) {
  .merit-ball {
    width: 400px;
    height: 400px;
  }
}

.merit-ball:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent url(../asset/images/common/bg01.svg) repeat top center/150px;
}

.merit-ttlbox {
  text-align: center;
  margin: 20px auto 0;
}

.merit-ttl {
  display: inline-block;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1;
  position: relative;
  z-index: 1;
  padding-bottom: 1em;
  background: transparent url(../asset/images/common/border_w.svg) no-repeat bottom center/contain;
}

.merit-ttl ._merit_num {
  color: #fff000;
  font-size: 5rem;
  text-shadow: 1px 1px 1px #5b3616, -1px 1px 1px #5b3616, 1px -1px 1px #5b3616, -1px -1px 1px #5b3616, 1px 0px 1px #5b3616, 0px 1px 1px #5b3616, -1px 0px 1px #5b3616, 0px -1px 1px #5b3616;
  position: relative;
}

.merit-ttl ._merit_num:before {
  content: "";
  width: 17px;
  height: 12px;
  position: absolute;
  top: 10px;
  left: -12px;
  background: transparent url(../asset/images/top/txt_deco.svg) no-repeat top center/cover;
}

.merit-ttl ._merit {
  color: #fff000;
  font-size: 4.2rem;
  text-shadow: 1px 1px 1px #5b3616, -1px 1px 1px #5b3616, 1px -1px 1px #5b3616, -1px -1px 1px #5b3616, 1px 0px 1px #5b3616, 0px 1px 1px #5b3616, -1px 0px 1px #5b3616, 0px -1px 1px #5b3616;
}

.merit-container {
  position: relative;
}

.merit-container:before, .merit-container:after {
  background: #fad07d;
  content: "";
  width: 100vw;
  height: calc(100% + 40px);
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767px) {
  .merit-container:before, .merit-container:after {
    top: -100px;
    height: calc(100% + 140px);
  }
}

.merit-container:after {
  background: transparent url(../asset/images/common/bg01.svg) repeat top center/150px;
}

.merit-head {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .merit-head {
    font-size: 1.4rem;
  }
}

.merit-list {
  padding-top: 60px;
}

.merit-item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
  margin: auto;
  position: relative;
  gap: 20px;
  max-width: 820px;
  margin-top: 32px;
}

@media (max-width: 767px) {
  .merit-item {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
}

.merit-imgs {
  position: relative;
  z-index: 2;
}

@media (max-width: 767px) {
  .merit-imgs {
    max-width: 80%;
    margin: auto;
  }
}

.merit-img {
  width: 256px;
}

@media (max-width: 767px) {
  .merit-img {
    width: 100%;
  }
}

.merit-deco {
  position: absolute;
  top: 0;
  left: -30px;
  width: 100px;
  z-index: 1;
}

.merit-txtbox {
  width: calc(100% - 256px);
  position: relative;
  z-index: 1;
}

@media (max-width: 1228px) {
  .merit-txtbox {
    padding-right: 1em;
  }
}

@media (max-width: 767px) {
  .merit-txtbox {
    width: 80%;
    margin: auto;
    background: #fff;
    text-align: center;
    padding: 0.25em 0.5em;
  }
}

.merit-txtbox:before {
  content: "";
  border-radius: 35px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 720px;
  z-index: -1;
}

@media (max-width: 1228px) {
  .merit-txtbox:before {
    right: 0;
    left: auto;
    width: calc(100% + 120px);
    transform: translateY(-50%);
  }
}

@media (max-width: 767px) {
  .merit-txtbox:before {
    display: none;
  }
}

.merit-txt {
  font-size: 2.4rem;
  font-weight: bold;
  padding: 0.5em 0;
}

.merit-txt span {
  color: #ef888c;
}

@media (max-width: 767px) {
  .merit-txt {
    font-size: 2rem;
  }
}

.merit-btnbox {
  position: relative;
  font-weight: bold;
  width: 100%;
  margin: 80px auto 0;
  max-width: 400px;
  z-index: 1;
}

.merit-btnbox:before {
  border: solid #5b3616;
  border-width: 0 8px 0 8px;
  content: "";
  height: 42px;
  left: 50%;
  position: absolute;
  top: calc(100% - 2px);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: calc(100% - 40px);
  z-index: -1;
}

.merit-btn {
  border: 5px solid #5b3616;
  border-radius: 22px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  font-size: 2rem;
  padding: 24px 0 10px;
  gap: 10px;
  transition: 0.2s;
  background: #fff9b8;
}

.merit-btn:hover {
  opacity: 0.7;
}

.merit-btn span {
  display: block;
  position: relative;
  background: #f7b525;
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.merit-btn span:before {
  width: 25px;
  height: 6px;
  background: transparent url(../asset/images/common/arrow.svg) no-repeat top center/contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
}
